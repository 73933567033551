<script setup lang="ts">
import { getHomeVideoPage } from '~/api/home'
import type { MediaType } from '~/types'
import { MediaTypeEnum } from '~/enums/const'

definePageMeta({
  key: (route) => route.fullPath,
  validate: ({ params }) => !!params.type
})

const route = useRoute()
const isLoading = ref<boolean>(true)
const initParams = ref({ tabs: getVideoType(route.params.type || 1, true), years: 0, video: 0, area: 0, recommend: 1 })
const totalPage = ref(0)
const videoListData = ref<any>([])

async function getVideoNewData(params: any, page: number) {
  try {
    const { data } = await getHomeVideoPage({
      area: params.area || undefined,
      years: params.years || undefined,
      categoryTagId: params.video || undefined,
      orderType: params.recommend,
      videoSiteType: params.tabs,
      pageNum: page,
      pageSize: 20
    })
    totalPage.value = data.totalRecord
    videoListData.value.push(...data.record)
    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}

async function getPlayCollectNewData(page: number) {
  if (videoListData.value.length) {
    await getVideoNewData(initParams.value, page)
  }
}
async function changeSelect(params: any) {
  isLoading.value = true
  initParams.value = params
  totalPage.value = 0
  videoListData.value = []
  await getVideoNewData(params, 1)
}

await getVideoNewData(initParams.value, 1)

useSeoMeta({
  title: MediaTypeEnum[route.params.type as MediaType]
})
</script>

<template>
  <SwitchTabs
    :video-type="{ type: $route.params.type }"
    @change-tabs="(params) => !isLoading && changeSelect(params)"
  />

  <div class="min-h-[55rem]">
    <MediaAutoLoadGrid
      v-if="totalPage"
      :type="$route.params.type"
      :items="videoListData"
      :count="totalPage"
      :fetch="getPlayCollectNewData"
    />

    <div v-if="!totalPage && isLoading" animate-pulse py50>
      <div i-carbon:circle-dash ma animate-spin text-4xl />
    </div>
    <div v-if="!totalPage && !isLoading" class="my25 text-center text-size-3">
      <NuxtImg class="m-a w40% lg:w30%" src="/Empty.png" />
      <span ct-ffffffb3> 暂无相关资源 </span>
    </div>
  </div>
</template>
