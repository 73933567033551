<script setup lang="ts">
import { getHomeVideoTypeList } from '~/api/home'
import { getVideoTypeListForServer } from '~/api/server'

interface Props {
  videoType: {
    type: string
  }
}
interface TabsType {
  type: number
  title: string
  typeName: string
}
interface RecommendTabsType {
  type: number
  title: string
}
const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits(['changeTabs'])
const tabsTypeList: TabsType[] = [
  {
    type: 1,
    typeName: 'movie',
    title: '电影'
  },
  {
    type: 2,
    typeName: 'tv',
    title: '电视剧'
  },
  {
    type: 3,
    typeName: 'show',
    title: '综艺'
  },
  {
    type: 4,
    typeName: 'cartoon',
    title: '动漫'
  }
]
const yearsTypeList = ref([])
const videoTypeList = ref([])
const areaTypeList = ref([])
const recommendTabsList: RecommendTabsType[] = [
  {
    type: 1,
    title: '最多播放'
  },
  {
    type: 2,
    title: '最新上架'
  },
  {
    type: 3,
    title: '收藏最多'
  }
]

const activatiTabs = ref(getVideoType(props.videoType.type || 1, true))
const activatiYears = ref(0)
const activatiVideo = ref(0)
const activatiArea = ref(0)
const activatRecommend = ref(1)

async function getHomeVideoType() {
  const requestApi = process.server ? getVideoTypeListForServer : getHomeVideoTypeList
  try {
    const { data } = await requestApi({ videoSiteType: getVideoType(props.videoType.type || 1, true) })
    yearsTypeList.value = data.yearsString.split(',')
    videoTypeList.value = data.videoTypeString
    areaTypeList.value = data.dateString.split(',')
  } catch (error) {}
}

await getHomeVideoType()

function handelTabsType(type: string | number, val: any) {
  switch (type) {
    case 'years':
      activatiYears.value = val
      break
    case 'video':
      activatiVideo.value = val.categoryTagId || 0
      break
    case 'area':
      activatiArea.value = val
      break
    case 'recommend':
      activatRecommend.value = val.type
      break
    default:
      break
  }
  emits('changeTabs', {
    tabs: activatiTabs.value,
    years: activatiYears.value,
    video: activatiVideo.value,
    area: activatiArea.value,
    recommend: activatRecommend.value
  })
}
</script>

<template>
  <!-- 电影 电视剧 综艺  动漫 -->
  <div class="mt-3 whitespace-nowrap text-center font-size-3 xl:font-size-4">
    <div class="mb-5 flex border-b font-size-5 font-500 bbc-str-d9d9d91a xl:font-size-5.5">
      <NuxtLink
        v-for="(item, index) in tabsTypeList"
        :key="index"
        :to="`/channel/${item.typeName}`"
        :class="[item.type === activatiTabs ? 'ct-f97316' : 'ct-ffffffb3']"
        class="mb-2 mr-5 cursor-pointer rd-5 xl:mr-8"
      >
        {{ item.title }}
      </NuxtLink>
    </div>
    <ul
      v-if="yearsTypeList.length"
      class="mb-5 h-5 flex line-height-5 xl:mr-3 xl:h-6 xl:min-w-22 xl:px-3 xl:line-height-6"
    >
      <li
        :class="{ 'bg-f97316': activatiYears === 0 }"
        class="mr-2 cursor-pointer rd-5 px-2"
        @click="handelTabsType('years', 0)"
      >
        全部年份
      </li>
      <li class="channerl-scroll flex">
        <p
          v-for="(item, index) in yearsTypeList"
          :key="index"
          :class="{ 'bg-f97316': item === activatiYears }"
          class="cursor-pointer rd-5 px-2 xl:mr-3 xl:h-6 xl:px-3 xl:line-height-6"
          @click="handelTabsType('years', item)"
        >
          {{ item }}
        </p>
      </li>
    </ul>
    <ul
      v-if="videoTypeList.length"
      class="mb-5 h-5 flex line-height-5 xl:mr-3 xl:h-6 xl:min-w-22 xl:px-3 xl:line-height-6"
    >
      <li
        :class="{ 'bg-f97316': activatiVideo === 0 }"
        class="mr-2 cursor-pointer rd-5 px-2"
        @click="handelTabsType('video', 0)"
      >
        全部类型
      </li>
      <li class="channerl-scroll flex">
        <p
          v-for="(item, index) in videoTypeList"
          :key="index"
          :class="{ 'bg-f97316': item.categoryTagId === activatiVideo }"
          class="cursor-pointer rd-5 px-2 xl:mr-3 xl:h-6 xl:px-3 xl:line-height-6"
          @click="handelTabsType('video', item)"
        >
          {{ item.categoryTagName }}
        </p>
      </li>
    </ul>
    <ul
      v-if="areaTypeList.length"
      class="mb-5 h-5 flex line-height-5 xl:mr-3 xl:h-6 xl:min-w-22 xl:px-3 xl:line-height-6"
    >
      <li
        :class="{ 'bg-f97316': activatiArea === 0 }"
        class="mr-2 cursor-pointer rd-5 px-2"
        @click="handelTabsType('area', 0)"
      >
        全部地区
      </li>
      <li class="channerl-scroll flex">
        <p
          v-for="(item, index) in areaTypeList"
          :key="index"
          :class="{ 'bg-f97316': item === activatiArea }"
          class="cursor-pointer rd-5 px-2 xl:mr-3 xl:h-6 xl:px-3 xl:line-height-6"
          @click="handelTabsType('area', item)"
        >
          {{ item }}
        </p>
      </li>
    </ul>
    <!-- 最多播放 最新上架 收藏最多 -->
    <ul class="flex border-b font-size-4 font-500 bbc-str-d9d9d91a xl:font-size-4.5">
      <li
        v-for="(item, index) in recommendTabsList"
        :key="index"
        :class="[item.type === activatRecommend ? 'ct-f97316' : 'ct-ffffffb3']"
        class="mb-2 mr-5 mt-5 cursor-pointer rd-5 xl:mr-8"
        text-center
        @click="handelTabsType('recommend', item)"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<style>
.channerl-scroll {
  overflow: auto;
  white-space: nowrap;
}
.channerl-scroll::-webkit-scrollbar {
  display: none;
}
</style>
